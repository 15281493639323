

import ProblemImage from "./ProblemImage.vue";
import ProblemPagination from "./ProblemPagination.vue";

export default {
  name: 'ProblemCard',
  components: {ProblemImage, ProblemPagination},
  props: {
    problem: {
      required: true,
    },
    selectedProblem: {
      required: true,
    },
    allProblemsIds: {
      required: true,
    },
  },
  data() {
    return {
      showSolution: false
    }
  },
  watch: {
    selectedProblem(val) {
      this.showSolution = false
    }
  }
}


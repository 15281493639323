<template>
  <router-link
    v-if="item.june"
    :to="url(item.year, 'Juny', item.june[0])"
  >
    <v-card
      :style="`border-left: 6px solid ${color}`"
      class="rounded-lg anys-card"
      flat
      :height="small ? 50 : 70"
      :width="$vuetify.breakpoint.xs || small ? '100%' : 160"
    >
      <div class="px-4 py-2 d-flex flex-column rounded-l-0"
           style="height: 100%; width: 100%; border: thin solid rgba(217, 220, 231, 0.7) !important; border-left: none !important;"
      >

        <span v-if="$route" class="font-weight-medium blue-grey--text text--darken-1 two-lines-truncate mt-1 font-rubik"
              style="font-size: 16px !important; color: #434343 !important;"
        >
          {{ item.year }}
        </span>

        <v-spacer></v-spacer>

        <div
          v-if="!small"
          class="d-flex">
              <span class="caption font-rubik" style="color: rgba(32,32,32,0.7)">
                {{ getSeriesNumber(item) }} series
              </span>
          <v-spacer></v-spacer>
          <v-btn x-small icon style="border: thin solid #eaeaea">
            <v-icon x-small>{{mdiChevronRight}}</v-icon>
          </v-btn>
        </div>
      </div>

    </v-card>

  </router-link>
</template>

<script>
import {mdiChevronRight} from '/src/assets/mdi.json'

export default {
  name: "CardYear2022",
  props: {
    item: {
      required: true
    },
    color: {
      type: String | null,
      required: true
    },
    small: {
      type: Boolean
    }
  },
  data() {
    return {
      mdiChevronRight: mdiChevronRight
    }
  },
  methods: {
    url(year, month, serie) {
      if (this.item) {
        const assig = this.$route.params.subject_name
        const path = '/selectivitat/' + assig + '/examens/' + year + '/' + month + '?serie=' + serie

        return path
      }
      return ''
    },
    getSeriesNumber(item) {
      let sum = 0;
      if (item.june) sum += item.june.length;
      if (item.september) sum += item.september.length;
      return sum
    }
  }
}
</script>

<style scoped>
.anys-card {
  box-shadow: 0px 2px 4px rgba(188, 198, 212, 0.14), 0px 4px 5px rgba(188, 198, 212, 0.12), 0px 1px 10px rgba(188, 198, 212, 0.2) !important;
  cursor: pointer;
  width: 250px;
  border-radius: 5px !important;
}
</style>

<template>
  <v-card
    v-if="problem"
    @click="goTo(problem['problem_id'])"
    :ripple="false"
    class="rounded-lg"
    style="cursor: pointer;position: relative; border: 1px solid #e8e8e8; box-shadow: 0px 8px 12px rgba(2,30,84,.06);"
  >

    <div class="py-2 px-5 d-flex align-center">
<!--            {{problem.problem_id}}-->
          <span
            v-if="$route.params.year && problem && problem.topics_names"
            style="font-size: 15px; letter-spacing: normal;"
                class="font-rubik font-weight-medium text-capitalize"
          >
            {{ problem.topics_names.join(", ") }}
          </span>

          <span
            v-else
            style="font-size: 16px; letter-spacing: normal;"
                class="font-rubik font-weight-medium"
          >
          {{ problem.month }} {{ problem.year }}, {{ problem.month.toLowerCase() }}, sèrie {{ problem.serie }}
          </span>


    </div>

    <v-divider></v-divider>


    <v-card color="transparent"
            class="rounded-lg pb-2"
            flat
    >
      <div style="background: white; position: relative" class="d-flex align-start">


        <div
          style="position: absolute;  top: 0; left: 0; z-index: 10; width: 100%; height: 100%"
        >
<!--          <svg-->
<!--            width="100%"-->
<!--            height="100%"-->
<!--            viewBox="0 0 500 75"-->
<!--            preserveAspectRatio="xMinYMid meet"-->
<!--            xmlns="http://www.w3.org/2000/svg"-->
<!--            xmlns:xlink="http://www.w3.org/1999/xlink"-->
<!--          >-->
<!--            <text-->
<!--              font-size="42"-->
<!--              fill="rgba(20, 102, 199, 0.2)"-->
<!--              class="font-rubik font-weight-black"-->
<!--              dominant-baseline="middle" text-anchor="middle"-->
<!--              style="letter-spacing: -3px"-->
<!--              :transform="'translate(0,70) rotate(' + -15 + ')'"-->
<!--              x="50%"-->
<!--              y="50%"-->
<!--            >-->
<!--              examenselectivitat.cat-->
<!--            </text>-->
<!--          </svg>-->
        </div>

        <v-img
          @load="handleLoad"
          :src="getImage()"
          contain
          :key="imageKey"
          class="image-preview pt-3 rounded-0"
        ></v-img>
      </div>

    </v-card>

  </v-card>
</template>

<script>
export default {
  name: "ItemList",
  props: {
    problem: {
      required: true
    },
    idx: {
      required: true
    }
  },
  data() {
    return {
      loadingImage: false,

    }
  },
  computed: {
    imageKey() {
      return `${this.problem.subject_path}_${this.problem.year}_${this.problem.serie}_${this.problem.problem_id}`
    },
  },
  methods: {
    handleLoad() {
      this.loadingImage = true;
    },
    getImage() {
      return `https://examenselectivitat.cat:3000/api/images/${this.problem.subject_path}/${this.problem.year}/${this.problem.serie}/${this.problem.problem_id}/problem`
    },
    goTo(problem_id) {
      if (this.$route.params.topic_path)
        this.$router.push({path: `/selectivitat/${this.$route.params.subject_name}/per-temes/${this.$route.params.topic_path}?problem=${problem_id}`})
      else
        this.$router.push({path: `/selectivitat/${this.$route.params.subject_name}/examens/${this.$route.params.year}/${this.$route.params.mes}?serie=${this.$route.query.serie}&problem=${problem_id}`})


    },
  }
}
</script>

<style scoped>
.three-lines {
  display: -webkit-box;
  line-height: 20px;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.image-preview {
  /*max-height: 360px !important;*/
  object-fit: cover !important;
  object-position: center top !important;
  border-bottom: none !important;
}

.full-text {
  text-align: justify;
}

.full-text:after {
  content: "";
  display: inline-block;
  width: 100%;
}

</style>

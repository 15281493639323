<template>
  <v-card flat color="transparent" style="border-bottom: none !important;">
    <div v-if="topicsFiltered && topicsFiltered.length > 0">
      <v-card
        v-for="(tema, idx) in topicsFiltered"
        class="pa-3 temes-card mb-3"
        :style="small ? `border-radius: 6px !important` : ''"
        tile flat
      >
        <router-link class="d-flex align-center"
                     :to="getUrl(tema)">
          <v-card
            v-if="!small"
            height="40" width="40" class="text-center align-center d-flex flex-column rounded-circle mr-4"
            :style="`background: linear-gradient(to bottom,rgba(255,255,255,0.7),rgba(255,255,255,0.7)),${color}`"
            flat>
                <span class="font-weight-bold mt-2 font-rubik"
                      style="font-size: 17px"
                      :style="`color: ${color}`"
                >{{ idx + 1 }}</span>
          </v-card>
          <div style="flex: 1" class="d-flex flex-wrap align-center justify-start text-start">
            <div class="d-flex flex-column">
              <span
                :class="small ? 'pl-1' : ''"
                class="font-weight-medium font-rubik"
                style="color: #434343 !important"
              >
                {{ tema.topic_name }}
              </span>
              <span
                v-if="!small"
                style="font-size: 15px; word-wrap: break-word; color: rgba(32,32,32,0.7)"
                class="two-lines"
              >
                    {{ tema.topic_subtitle }}
                  </span>
            </div>
          </div>
          <span
            v-if="!small"
            class="mx-3 grey--text font-rubik"
            style="font-size: 15px; "
          >
            {{ tema.counter }} problemes
          </span>
        </router-link>
      </v-card>
    </div>

    <div
      v-else
      class="d-flex flex-column"
    >
      <div
        v-for="sk in 4"
        style="height: 65px; border: thin solid #e9e9e9; border-radius: 6px !important;"
        class="d-flex align-center px-5 mb-3 temes-card white">

        <v-skeleton-loader
          type="list-item-avatar"
          class="rounded-circle px-0 mx-0"
        ></v-skeleton-loader>
<!--        <v-skeleton-loader-->
<!--          width="40px"-->
<!--          height="40px"-->
<!--          type="image"-->
<!--          class="rounded-circle"-->
<!--        ></v-skeleton-loader>-->
<!--        <v-skeleton-loader-->
<!--          width="20%" class="ml-4"-->
<!--          type="text"-->
<!--        ></v-skeleton-loader>-->
<!--        <v-spacer></v-spacer>-->
        <v-skeleton-loader
          width="100px"
          type="text"
          style="flex: 1"
        ></v-skeleton-loader>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "Temes",
  props: {
    topics: {
      required: true
    },
    color: {
      required: true
    },
    small: {
      default: false
    }
  },
  computed: {
    topicsFiltered() {
      return this.topics && this.topics.length > 0 ? this.topics.filter(t => t.counter > 1) : []
      // return []
    }
  },
  methods: {
    getUrl(tema) {
      return '/selectivitat/' + this.$route.params.subject_name + '/per-temes/' + tema.topic_path
    }
  }
}
</script>

<style scoped>


.temes-card {
  box-shadow: 0px 2px 4px rgba(188, 198, 212, 0.07), 0px 4px 5px rgba(188, 198, 212, 0.06), 0px 1px 10px rgba(188, 198, 212, 0.1) !important;
  border: thin solid rgba(217, 220, 231, 0.7) !important;
  border-radius: 10px !important;
}


.two-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

import { render, staticRenderFns } from "./ExamensRecomenats.vue?vue&type=template&id=0e130883&scoped=true"
import script from "./ExamensRecomenats.vue?vue&type=script&lang=js"
export * from "./ExamensRecomenats.vue?vue&type=script&lang=js"
import style0 from "./ExamensRecomenats.vue?vue&type=style&index=0&id=0e130883&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e130883",
  null
  
)

export default component.exports
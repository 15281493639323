

import Adsense from "../../../../components/anuncis/Adsense.vue";
import CheckoutSuggested from "../../../shop/cart/components/CartSuggested.vue";

export default {
  name: "HorizontalAdsAfterSolution",
  components: {CheckoutSuggested, Adsense},
  mounted() {
    this.$forceUpdate();

    if (this.$root.currentUser === null || !this.$root.currentUser.premium) {

      setInterval(() => {
        this.refreshAd++;
      }, 30 * 1000)


      setTimeout(() => {
        // this.showAdsDialog = true;
      }, 5000)
    }

    this.fetchAllBooks()
  },
  data() {
    return {
      refreshAd: 0,
      shopList: [],
    }
  },
  methods: {
    async fetchAllBooks() {
      let {data} = await this.axios.get('/shop-list')
      this.shopList = data;
    },
  }
}



import ProblemListItem from "./ProblemListItem.vue";

export default {
  components: {ProblemListItem},
  props: {
    problems: {
      required: true
    },
    selectedProblem: {
      required: true
    }
  },
  mounted() {
    // Espera un segon per ensenyar la llista i així no fer dos requests de problemes seguits al fer instersect
    setTimeout(() => {
      this.showList = true
    }, 1000)
  },
  data() {
    return {
      showList: false
    }
  },
  methods: {
    intersectInfiniteScroll(entries, observer) {
      if (entries[0].isIntersecting) {
        console.log("intersectInfiniteScroll")
        this.$emit('fetchProblems')
      }
    },
  }
}
